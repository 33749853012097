import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { createUseStyles } from 'react-jss';
import { Designs } from '../../../data/designs';

const useStyles = createUseStyles({
  title: {
    color: 'red',
    textTransform: 'uppercase',
  },
  titleWrapper: {
    display: 'block',
    textAlign: 'center',
  },
  linkStyle: {
    textDecoration: 'none',
  },
});

export default function Viewer() {
  const classes = useStyles();
  return (
    <div className='shirts-view'>
      {Designs.map((design, i) => (
        <div key={i}>
          <Link
            to={`/designer?shirt=${design.name}`}
            className={classes.linkStyle}
          >
            <LazyLoadImage
              src={`/pcw-thumbs/${design.designFront.replace(
                '-front',
                ''
              )}.jpg`}
              effect='blur'
            />
            <div className={classes.titleWrapper}>
              <h4 className={classes.title}>PRO {design.name}</h4>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}

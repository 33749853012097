import * as React from "react"
import { Container, Row, Col } from 'react-grid-system';

import Layout from "../components/theme/layout"
import Seo from "../components/theme/seo"

import Viewer from '../components/elements/viewer'

export default function index() {
    return (
        <Layout>
            <Seo title="Home" />
            <Container>
                <Row className="center">
                    <Col lg={7} md={9}>
                        <h2 className="text-center mb-10">Choose from our many designs and explore our unique customization options.</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Viewer />
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}
